import { FC } from 'react';
import { useTimeAgo } from 'react-time-ago';
import { InheritSpan } from '~src/components/text/spans';

export const TimeAgoSpan: FC<{ timestamp: Date }> = ({ timestamp }) => {
  const timeAgoResult = useTimeAgo({
    date: timestamp,
    locale: 'en-US',
  });

  return (
    <InheritSpan color={'gray.400'}>{timeAgoResult.formattedDate}</InheritSpan>
  );
};
