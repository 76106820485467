import { defineTokens } from '@pandacss/dev';
import { toTokens } from '~src/utils/panda/toTokens';
import { betterBreakpoints } from '../breakpoints';
import { padding } from './padding';

export const smallElementSizes = {
  squareIcon: 28,
  navIsland: 40,
  highlightSmallLine: 22,
  crestImg: 26,
  pillHeight: 26,
  navLogo: 32,
  largePfp: 36,
  mediumPfp: 28,
  pfp: 24,
} as const;

export const iconSizes = {
  twelve: 12,
  fourteen: 14,
  sixteen: 16,
  eighteen: 18,
  twenty: 20,
  twentyFour: 24,
  badge: 32,
  largeBadge: 40,
};

export const randomSizes = {
  // cmdDialog: 590
  luksoMiniDappWidth: 300,
  luksoMinMiniDappHeight: 408,
} as const;

export const navSizes = {
  subNav: 56,
  stickedSubNav: 64,
  nav: 72,
  mobileNav: 52,
} as const;

export type NavSizes = keyof typeof navSizes;

export const stickySizes = {
  stickyTopFromSubNav: padding.largeBox + navSizes.stickedSubNav,
};

export type StickySizes = keyof typeof stickySizes;

const cssDimensions = {
  '100%': '100%',
  'fit-content': 'fit-content',
} as const;

export const sizes = defineTokens.sizes({
  ...toTokens(
    {
      ...smallElementSizes,
      ...navSizes,
      ...stickySizes,
      ...randomSizes,
      ...betterBreakpoints,
      ...padding,
    },
    (v) => `${v}px`,
  ),
  icon: toTokens(iconSizes, (v) => `${v}px`),
  ...toTokens(cssDimensions),
});
