import type { BlockverseProjectId } from '@pob/blockverse';
import { ArrowUpRight } from 'lucide-react';
import Link from 'next/link';
import { FC } from 'react';
import { Spinner } from 'react-activity';
import { match } from 'ts-pattern';
import { BaseA, SquareMinorA } from '~src/components/anchor';
import { SquareIconButton } from '~src/components/button';
import BasketShopping from '~src/components/icon/basket-shopping';
import { DisplayP } from '~src/components/text';
import { useBestDisplayName } from '~src/hooks/address/useBestDisplayName';
import type { BlockverseObjId } from '~src/shared-apps/blockverse/types';
import type {
  CheckoutItemMintedProps,
  CheckoutItemStateProps,
} from '~src/shared-apps/checkout/types/state';
import { Box, Flex } from '~src/styled-system/jsx';
import { token } from '~src/styled-system/tokens';
import {
  useHeadlessAddToCartButton,
  type HeadlessAddToCartButtonProps,
} from '../../hooks/useHeadlessAddToCartButton';
import {
  useHeadlessCheckoutButton,
  type HeadlessCheckoutButtonProps,
} from '../../hooks/useHeadlessCheckoutButton';

export type CheckoutItemPostActionsMintableAndPendingProps = {
  disableCartButton?: boolean;
};

export type CheckoutItemPostActionsProps = {
  objId: BlockverseObjId<BlockverseProjectId>;
  itemState?: CheckoutItemStateProps;
  itemHref?: string;
  prepareCheckoutOnOpen?: HeadlessCheckoutButtonProps['prepareCheckoutOnOpen'];
} & CheckoutItemPostActionsMintableAndPendingProps;

export const CheckoutItemPostActions: FC<CheckoutItemPostActionsProps> = ({
  itemState,
  objId,
  prepareCheckoutOnOpen,
  disableCartButton,
}) => {
  return (
    <>
      {match(itemState)
        .with(undefined, () => <LoadingState />)
        .with({ type: 'coming-soon' }, () => <ComingSoonState />)
        .with({ type: 'loading' }, () => <LoadingState />)
        .with({ type: 'mintable' }, (s) => (
          <MintableAndPendingState
            objId={objId}
            itemState={s}
            prepareCheckoutOnOpen={prepareCheckoutOnOpen}
            disableCartButton={disableCartButton}
          />
        ))
        .with({ type: 'pending' }, (s) => (
          <MintableAndPendingState
            objId={objId}
            itemState={s}
            prepareCheckoutOnOpen={prepareCheckoutOnOpen}
            disableCartButton={disableCartButton}
          />
        ))
        .with({ type: 'claim' }, () => <ClaimDetails />)
        .with({ type: 'minted' }, (s) => <MintedState itemState={s} />)
        .exhaustive()}
    </>
  );
};

export const ComingSoonState = () => {
  return (
    <Box>
      <DisplayP.Caption color={'gray.400'}>Minting Soon</DisplayP.Caption>
    </Box>
  );
};

export const ClaimDetails = () => {
  return (
    <Box>
      <SquareMinorA btnType={'secondary'} color={'gray.100'}>
        Claim
      </SquareMinorA>
    </Box>
  );
};

export const LoadingState = () => {
  return (
    <Box>
      <Spinner color={token.var('colors.gray.300')} size={10} />
    </Box>
  );
};

export const MintableAndPendingState: FC<
  HeadlessCheckoutButtonProps & CheckoutItemPostActionsMintableAndPendingProps
> = ({ objId, itemState, prepareCheckoutOnOpen, disableCartButton }) => {
  const checkoutButtonProps = useHeadlessCheckoutButton({
    objId,
    itemState,
    labels: {
      mintNow: 'Mint',
    },
    prepareCheckoutOnOpen,
  });

  return (
    <Flex gap={'increment'} alignItems={'center'}>
      {disableCartButton ? null : (
        <InCartButton objId={objId} itemState={itemState} />
      )}
      <SquareMinorA
        btnType={'secondary'}
        color={'gray.100'}
        {...checkoutButtonProps}
      />
    </Flex>
  );
};

const InCartButton: FC<HeadlessAddToCartButtonProps> = ({ ...props }) => {
  const addToCartButtonProps = useHeadlessAddToCartButton(props);

  return addToCartButtonProps.isInCart ? (
    <DisplayP.Caption color={'gray.500'}>
      <Link passHref legacyBehavior href={addToCartButtonProps.cartUrl as any}>
        <BaseA
          display={'flex'}
          alignItems={'center'}
          _hover={{
            textDecoration: 'underline',
          }}
          gap={'quarterIncrement'}
        >
          In Cart
          <ArrowUpRight size={16} />
        </BaseA>
      </Link>
    </DisplayP.Caption>
  ) : (
    <SquareIconButton
      onClick={addToCartButtonProps.onClick}
      disabled={addToCartButtonProps.disabled}
      bg={'gray.100'}
      color={'gray.500'}
      css={{
        '& > svg': {
          width: 'icon.fourteen',
          height: 'icon.fourteen',
        },
      }}
    >
      <BasketShopping />
    </SquareIconButton>
  );
};

export const MintedState: FC<{
  itemState: CheckoutItemMintedProps;
}> = ({ itemState }) => {
  const bestName = useBestDisplayName(itemState.owner);
  return (
    <Flex gap={'increment'}>
      <DisplayP.Caption color={'gray.300'}>Owner</DisplayP.Caption>
      <DisplayP.Caption color={'gray.600'}>{bestName}</DisplayP.Caption>
    </Flex>
  );
};
