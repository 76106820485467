import type { BlockverseProjectId } from '@pob/blockverse';
import type { AvailableChainId } from '@protocol/chains';
import { useMemo } from 'react';
import { PROJECT_SUPPORTED_CHECKOUT_CHAINS } from '~src/config/checkout';
import { isSupportedCheckoutProjectId } from '~src/config/utils/isSupportedCheckoutProjectId';
import type { BlockverseObjId } from '~src/shared-apps/blockverse/types';
import { useCheckoutContext } from '~src/shared-apps/checkout/providers/Checkout';
import type { CheckoutItemStateProps } from '~src/shared-apps/checkout/types/state';

export type HeadlessCheckoutButtonProps = {
  objId: BlockverseObjId<BlockverseProjectId>;
  itemState: CheckoutItemStateProps;
  labels?: Partial<{
    minting: string;
    mintNow: string;
  }>;
  prepareCheckoutOnOpen?: () => void;
};

export const useHeadlessCheckoutButton = ({
  objId,
  itemState,
  labels,
  prepareCheckoutOnOpen,
}: HeadlessCheckoutButtonProps) => {
  const { isModalOpen, onCheckoutOpen } = useCheckoutContext();
  const isCheckoutEnabled = useMemo(() => {
    const [projectId, chainId, ..._] = objId;
    const isCheckoutForProjectEnabled = isSupportedCheckoutProjectId(projectId);
    const isChainSupported = !!(
      PROJECT_SUPPORTED_CHECKOUT_CHAINS[projectId] as Record<
        number,
        AvailableChainId | undefined
      >
    )[chainId];
    return isCheckoutForProjectEnabled && isChainSupported;
  }, [objId]);

  return {
    onClick: () => onCheckoutOpen({ prepareCheckoutOnOpen }),
    disabled:
      !isCheckoutEnabled || isModalOpen || itemState?.type === 'pending',
    children:
      isModalOpen || itemState?.type === 'pending'
        ? labels?.minting ?? 'Minting'
        : labels?.mintNow ?? 'Mint Now',
  };
};
