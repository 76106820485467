import type { ImageProps } from 'next/image';
import { FC, ReactNode, useMemo } from 'react';
import { useMeasure } from 'react-use';
import { RectReadOnly } from 'react-use-measure';
import { css, cx } from '~src/styled-system/css';
import { styled } from '~src/styled-system/jsx';
import type { SystemStyleObject } from '~src/styled-system/types';
import { getBase64PlaceholderImage } from '~src/utils/image/placeholder';
import { BaseLoadingImg } from '../img';

export type LightBoxProps = {
  className?: string;
  css?: SystemStyleObject;
  children: ReactNode;
  contentRatio: number;
  constrainingPercentage?: `${number}%`;
  otherLightBoxChildren?: ReactNode;
  defaultRendering?:
    | 'height-constrained'
    | 'width-constrained'
    | 'no-rendering';
};

const lightBoxBaseLoadingImgCss = css.raw({
  imageRendering: 'pixelated',
  objectFit: 'contain',
  display: 'block',
});

const lightBoxBaseLoadingImg = css(lightBoxBaseLoadingImgCss);

export const PixelatedLightBoxBaseLoadingImg: FC<ImageProps> = ({
  className,
  ...props
}) => {
  return (
    <BaseLoadingImg
      className={cx(className, lightBoxBaseLoadingImg)}
      fill
      unoptimized
      onError={(e) => {
        e.currentTarget.src = getBase64PlaceholderImage(1, 1, 'transparent');
      }}
      {...props}
    />
  );
};

export const LightBox: FC<LightBoxProps> = ({
  className,
  css,
  contentRatio,
  children,
  constrainingPercentage,
  otherLightBoxChildren,
  defaultRendering,
}) => {
  const [lightBoxRef, lightBoxBounds] = useMeasure();

  return (
    <LightBoxContainer ref={lightBoxRef as any} css={css} className={className}>
      <SmartLightBoxContentContainer
        lightBoxBounds={lightBoxBounds}
        contentRatio={contentRatio}
        constrainingPercentage={constrainingPercentage}
        defaultRendering={defaultRendering}
      >
        {children}
      </SmartLightBoxContentContainer>
      {otherLightBoxChildren}
    </LightBoxContainer>
  );
};

const LightBoxContainer = styled('div', {
  base: {
    pos: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
});

const SmartLightBoxContentContainer: FC<{
  children: ReactNode;
  lightBoxBounds: RectReadOnly;
  contentRatio: number;
  constrainingPercentage?: `${number}%`;
  defaultRendering?: LightBoxProps['defaultRendering'];
}> = ({
  lightBoxBounds,
  children,
  contentRatio,
  constrainingPercentage,
  defaultRendering,
}) => {
  const isHeightConstrained = useMemo(() => {
    const lightBoxRatio = lightBoxBounds.width / lightBoxBounds.height;
    if (lightBoxBounds.width === 0 || lightBoxBounds.height === 0) {
      return null;
    }
    // if light box ratio is wider than art ratio, then we need to adjust the height
    if (lightBoxRatio > contentRatio) {
      return true;
    }
    return false;
  }, [lightBoxBounds, contentRatio]);
  const style = useMemo(() => {
    const percentage = constrainingPercentage ?? '100%';

    // default behavior
    if (isHeightConstrained === null) {
      if (defaultRendering === 'height-constrained') {
        return { height: percentage, aspectRatio: contentRatio };
      }
      if (defaultRendering === 'width-constrained') {
        return { width: percentage, aspectRatio: contentRatio };
      }
      return {};
    }

    if (isHeightConstrained) {
      return { height: percentage, aspectRatio: contentRatio };
    }

    return { width: percentage, aspectRatio: contentRatio };
  }, [isHeightConstrained, constrainingPercentage, contentRatio]);

  if (isHeightConstrained === null && defaultRendering === 'no-rendering') {
    return null;
  }

  return (
    <LightBoxContentContainer style={style}>
      {children}
    </LightBoxContentContainer>
  );
};

const LightBoxContentContainer = styled('div', {
  base: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
});
