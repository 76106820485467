import { useMemo } from 'react';
import type { BlockverseObjId } from '~src/shared-apps/blockverse/types';
import { serializeId } from '~src/utils/strings/serializeId';
import type { SupportedCartProjectId } from '../../../config/cart';
import { useCart } from '../stores';

export const useIsInCart = <CartProject extends SupportedCartProjectId>(
  objId?: BlockverseObjId<CartProject>,
) => {
  const cart = useCart((s) => s.cart);
  return useMemo(() => {
    if (!objId) {
      return false;
    }
    const serializedObjId = serializeId(objId);
    return !!cart[serializedObjId];
  }, [cart, objId]);
};
