import { hiraethStepMintingAuthorityAbi } from '@hiraeth/protocol';
import { ZERO } from '@pob/shared';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { encodeFunctionData } from 'viem/utils';
import { useAccount, usePublicClient } from 'wagmi';
import {
  usePreferredAddress,
  usePreferredWalletClientState,
} from '~src/providers/PreferredNetwork';
// import { useWatchHiraethMintState } from '../hooks/hiraeth/useHiraethMintState';
import { CHAIN_ID_TO_CHAIN_LOOKUP } from '@protocol/chains';
import {
  CONTEXT_DEFAULT_FUNCTION,
  CONTEXT_DEFAULT_OBJ,
} from '~src/constants/defaults';
import { QUERY_LIVENESS, type QueryLiveness } from '~src/constants/query';
import type { BlockverseObjId } from '~src/shared-apps/blockverse/types';
import { CheckoutProvider, type CheckoutContext } from '~src/shared-apps/checkout/providers/Checkout';
import {
  CheckoutItem,
  CheckoutMintProps,
  CheckoutPriceProps,
  type CheckoutOnChainCall,
} from '~src/shared-apps/checkout/types';
import type { CheckoutErrorMap } from '~src/shared-apps/checkout/types/errors';
import type { CheckoutItemStateProps } from '~src/shared-apps/checkout/types/state';
import type { SetStateFromHook } from '~src/types/common';
import { useGetCurrentPriceByIndex } from '../../hooks/useGetCurrentPriceByIndex';
import { useHiraethItemCheckoutState } from '../../hooks/useHiraethItemCheckoutState';

type HiraethInTimeCheckoutContextType = {
  blockverseObjId: BlockverseObjId<'hiraeth'> | undefined;
  setBlockverseObjId: SetStateFromHook<BlockverseObjId<'hiraeth'> | undefined>;
  checkoutItemState: CheckoutItemStateProps | undefined;
};

export const HiraethInTimeCheckoutContext =
  createContext<HiraethInTimeCheckoutContextType>({
    blockverseObjId: undefined,
    setBlockverseObjId: CONTEXT_DEFAULT_FUNCTION,
    checkoutItemState: CONTEXT_DEFAULT_OBJ,
  });

export type HiraethInTimeCheckoutProviderProps = {
  defaultBlockverseObjId?: BlockverseObjId<'hiraeth'>;
  mintStateLiveness?: QueryLiveness;
  checkoutContext?: CheckoutContext;
};

export const useHiraethInTimeCheckoutContext = () => {
  const context = useContext(HiraethInTimeCheckoutContext);
  if (!context) {
    throw new Error(
      'useHiraethInTimeCheckoutContext must be used within a HiraethInTimeCheckoutContext',
    );
  }
  return context;
};

export const HiraethInTimeCheckoutProvider = ({
  children,
  ...props
}: HiraethInTimeCheckoutProviderProps & { children: React.ReactNode }) => {
  // const chainId = usePreferredChain().id;

  // if (!isSupportedChainForProjectForCheckout('hiraeth', chainId)) {
  //   return (
  //     <InnerHiraethEmptyCheckoutProvider>
  //       {children}
  //     </InnerHiraethEmptyCheckoutProvider>
  //   );
  // }

  return (
    <InnerHiraethInTimeCheckoutProvider {...props}>
      {children}
    </InnerHiraethInTimeCheckoutProvider>
  );
};

export const InnerHiraethInTimeCheckoutProvider = ({
  children,
  defaultBlockverseObjId,
  mintStateLiveness,
  checkoutContext,
}: HiraethInTimeCheckoutProviderProps & { children: React.ReactNode }) => {
  const [blockverseObjId, setBlockverseObjId] = useState<
    BlockverseObjId<'hiraeth'> | undefined
  >(defaultBlockverseObjId);

  useEffect(() => {
    if (!defaultBlockverseObjId) {
      return;
    }
    setBlockverseObjId(defaultBlockverseObjId);
  }, [defaultBlockverseObjId]);

  const chain = useMemo(() => {
    if (!blockverseObjId) {
      return undefined;
    }
    return CHAIN_ID_TO_CHAIN_LOOKUP[blockverseObjId[1]];
  }, [blockverseObjId]);

  const checkoutItemState = useHiraethItemCheckoutState(
    blockverseObjId,
    // undefined,
    !!mintStateLiveness ? QUERY_LIVENESS[mintStateLiveness] : undefined,
  );

  const getCurrentPriceByIndex = useGetCurrentPriceByIndex(
    blockverseObjId?.[1],
  );

  const checkoutItem = useMemo((): CheckoutItem | undefined => {
    if (!getCurrentPriceByIndex) {
      // better if we are returning undefined here
      return undefined;
    }

    if (!blockverseObjId) {
      return undefined;
    }

    return {
      objId: blockverseObjId,
      priceProps: {
        currency: chain?.nativeCurrency.symbol ?? 'ETH',
        price: getCurrentPriceByIndex(0),
        discount: ZERO,
      },
      // coercing to state props to satisfy type
      state: (checkoutItemState as CheckoutItemStateProps) ?? {
        type: 'mintable',
      },
    };
  }, [chain, blockverseObjId, checkoutItemState, getCurrentPriceByIndex]);

  const priceProps = useMemo((): CheckoutPriceProps | undefined => {
    if (!checkoutItem) {
      return undefined;
    }
    return {
      currency: checkoutItem.priceProps.currency,
      numItems: 1,
      totalPrice: checkoutItem.priceProps.price,
      finalTotalPrice: checkoutItem.priceProps.price,
      discount: ZERO,
    };
  }, [checkoutItem?.priceProps]);

  const uninitializedError = useMemo(():
    | CheckoutErrorMap['uninitialized']
    | undefined => {
    if (!blockverseObjId) {
      return {
        source: 'hiraeth',
        type: 'uninitialized',
        action: undefined,
        isCheckoutBlocking: true,
        isMintBlocking: true,
        hideActionOnMint: true,
      };
    }
  }, [blockverseObjId]);

  const claimedError = useMemo((): CheckoutErrorMap['minted'] | undefined => {
    if (!checkoutItemState) {
      return undefined;
    }

    if (checkoutItemState.type === 'minted') {
      return {
        source: 'hiraeth',
        type: 'minted',
        action: undefined,
        isCheckoutBlocking: true,
        isMintBlocking: true,
        hideActionOnMint: true,
      };
    }

    return undefined;
  }, [checkoutItemState]);

  const unableToMintError = useMemo(():
    | CheckoutErrorMap['unable-to-mint']
    | undefined => {
    if (!checkoutItemState) {
      return undefined;
    }

    if (checkoutItemState.type === 'claim') {
      return {
        source: 'hiraeth',
        type: 'unable-to-mint',
        reason: 'claim',
        action: undefined,
        isCheckoutBlocking: true,
        isMintBlocking: true,
        hideActionOnMint: true,
      };
    }

    return undefined;
  }, [checkoutItemState]);

  const errors = useMemo(() => {
    return {
      'minted': claimedError,
      'uninitialized': uninitializedError,
      'unable-to-mint': unableToMintError,
    } satisfies Partial<CheckoutErrorMap>;
  }, [claimedError, uninitializedError, unableToMintError]);

  const { address } = useAccount();
  const publicClient = usePublicClient();
  const walletClientState = usePreferredWalletClientState();

  const stepMinter = usePreferredAddress(
    (d) => d.projects.hiraeth?.steppedMintingAuthority,
  );

  const mintProps = useMemo((): CheckoutMintProps | undefined => {
    if (!address) {
      return undefined;
    }
    if (walletClientState !== 'preferred-chain') {
      return undefined;
    }
    if (!stepMinter) {
      return undefined;
    }

    if (!checkoutItem) {
      return undefined;
    }
    if (!priceProps) {
      return undefined;
    }
    const value = priceProps.finalTotalPrice;

    const prepareOnChainCalls = async (): Promise<CheckoutOnChainCall[]> => {
      const callData = encodeFunctionData({
        abi: hiraethStepMintingAuthorityAbi,
        functionName: 'mint',
        args: [address!, [checkoutItem.objId[2]]],
      });

      const gasLimit = await publicClient!.estimateGas({
        to: stepMinter,
        value: value,
        data: callData,
      });

      return [
        {
          args: {
            target: stepMinter,
            allowFailure: false,
            value,
            callData,
          },
          gasLimit,
        },
      ];
    };

    return {
      prepareOnChainCalls,
      key: ['hiraeth', 'mint', address, blockverseObjId],
    };
  }, [
    address,
    blockverseObjId,
    walletClientState,
    priceProps,
    checkoutItem,
    stepMinter,
  ]);

  const checkoutItems = useMemo(() => {
    if (!checkoutItem) {
      return [];
    }
    return [checkoutItem];
  }, [checkoutItem]);

  const state = useMemo(() => {
    return {
      blockverseObjId,
      setBlockverseObjId,
      checkoutItemState,
    };
  }, [blockverseObjId, setBlockverseObjId, checkoutItemState]);

  return (
    <HiraethInTimeCheckoutContext.Provider value={state}>
      <CheckoutProvider
        errors={errors}
        price={priceProps}
        mintProps={mintProps}
        checkoutItems={checkoutItems}
        mode={'in-time'}
        checkoutContext={checkoutContext}
      >
        {children}
      </CheckoutProvider>
    </HiraethInTimeCheckoutContext.Provider>
  );
};

// export const InnerHiraethEmptyCheckoutProvider = ({
//   children,
// }: HiraethInTimeCheckoutProviderProps & { children: React.ReactNode }) => {
//   const state = useMemo(() => {
//     return {
//       txnHash: undefined,
//       setTxnHash: () => {},
//       checkoutItemState: {
//         type: 'coming-soon',
//       } as const,
//     };
//   }, []);

//   return (
//     <HiraethInTimeCheckoutContext.Provider value={state}>
//       <EmptyCheckoutProvider>{children}</EmptyCheckoutProvider>
//     </HiraethInTimeCheckoutContext.Provider>
//   );
// };
