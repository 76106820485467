import { mapEntries } from '~src/utils/object/entries';

export const betterBreakpoints = {
  xs: 500,
  sm: 720,
  md: 960,
  lg: 1280,
  xl: 1500,
  cmdDialog: 590,
  // searchModal: 590,
} as const;

export type Breakpoints = keyof typeof betterBreakpoints;

export const breakpointsForPanda = mapEntries(
  betterBreakpoints,
  ([key, value]) => [key, `${value}px`],
);
